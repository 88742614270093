<script>
import { VclList } from 'vue-content-loading';

import {BillingProvider} from '@/enums';

function parseISO8601String(dateString) {
  var timebits = /^([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2})(?::([0-9]*)(\.[0-9]*)?)?(?:([+-])([0-9]{2})([0-9]{2}))?/;
  var m = timebits.exec(dateString);
  var resultDate;
  if (m) {
    var utcdate = Date.UTC(parseInt(m[1]),
        parseInt(m[2])-1, // months are zero-offset (!)
        parseInt(m[3]),
        parseInt(m[4]), parseInt(m[5]), // hh:mm
        (m[6] && parseInt(m[6]) || 0),  // optional seconds
        (m[7] && parseFloat(m[7])*1000) || 0); // optional fraction
    // utcdate is milliseconds since the epoch
    if (m[9] && m[10]) {
      var offsetMinutes = parseInt(m[9]) * 60 + parseInt(m[10]);
      utcdate += (m[8] === '+' ? -1 : +1) * offsetMinutes * 60000;
    }
    resultDate = new Date(utcdate);
  } else {
    resultDate = null;
  }
  return resultDate;
}

export default {
  props: ['invoice_id'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VclList
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/invoice/${this.invoice_id}/details`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.invoice = data.invoice;
          this.account = data.user;
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  },
  mounted() {
    console.log(this.$props.invoice_id);
    this.getData();
  },
  data() {
    return {
      BillingProvider: BillingProvider,
      ready: false,
      error: false,
      invoice: {},
      account: {}
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="invoice-title">
                <h4 class="float-right font-size-16" legal="Kaufbelegnummer">{{ $t('invoices.details.invoice') }} # {{ invoice_id }}</h4>
                <div class="mb-4">
                  <img src="@/assets/images/cfcloud.svg" alt="logo" height="40" />
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-6">
                  <address>
                    <strong legal="Leistungsempfänger">{{ $t('invoices.details.recipient.title') }}</strong>
                    <br/>{{ $t('invoices.details.recipient.account_id') }}: {{ account.id }}
                    <br />{{ $t('invoices.details.recipient.email') }}: {{ account.email }}
                  </address>
                </div>
                <div class="col-6 text-right">
                  <address>
                    <strong>{{ invoice.company_info.full_name }}</strong>
                    <br />{{ invoice.company_info.address.street }}
                    <br />{{ invoice.company_info.address.postal_code }} {{ invoice.company_info.address.city }}
                    <br />{{ invoice.company_info.address.country }}
                    <br />
                    <br />
                    <span>
                        <span class="small text-muted text-uppercase">
                          {{ $t('invoices.details.company.register_court') }}
                        </span>
                      {{ invoice.company_info.legal.register_court }} {{ invoice.company_info.legal.trade_register_number }}
                    </span>
                    <br>
                    <span>
                        <span class="small text-muted text-uppercase">
                          {{ $t('invoices.details.company.director') }}
                        </span>
                      {{ invoice.company_info.legal.managing_director }}
                    </span>
                    <br>
                    <span>
                      <span class="small text-muted text-uppercase">
                         {{ invoice.company_info.legal.tax.DE.text }}
                      </span>
                      {{ invoice.company_info.legal.tax.DE.id }}
                    </span>
                  </address>
                </div>
              </div>
              <div class="row">
                <div class="col-6 mt-3">
                  <address>
                    <strong>{{ $t('invoices.details.payment.title') }}</strong>
                    <br />{{ $t('invoices.details.payment.provider') }}:
                    <template v-if="invoice.payment.provider === BillingProvider.PAYPAL">
                      PayPal
                      <template v-if="invoice.payment.fulfilled">
                        <br />
                        {{ $t('invoices.details.payment.transaction_id') }}: {{ invoice.payment.transaction_id }} ({{ $t('invoices.details.payment.fulfilled') }})
                        <br />
                        {{ $t('invoices.details.payment.received_at') }}: {{ $d(new Date(invoice.payment.fulfilled_at), 'date') }}
                      </template>
                      <template v-else>
                        <b>{{ $t('invoices.details.payment.pending') }}</b>
                      </template>
                    </template>
                  </address>
                </div>
                <div class="col-6 mt-3 text-right">
                  <address>
                    <strong>{{ $t('invoices.details.date') }}</strong>
                    <br />{{ $d(new Date(invoice.created_at), 'date') }}
                    <br />
                    <br />
                  </address>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th style="width: 70px;">{{ $t('invoices.details.items.position') }}</th>
                    <th>{{ $t('invoices.details.items.description') }}</th>
                    <th>{{ $t('invoices.details.items.period') }}</th>
                    <th>Netto</th>
                    <th>{{ $t('invoices.details.items.amount') }}</th>
                    <th>{{ $t('invoices.details.items.tax') }} ({{ invoice.tax.amount }} % {{invoice.tax.abbreviation}})</th>
                    <th class="text-right">{{ $t('invoices.details.items.subtotal') }} (Brutto)</th>
                  </tr>
                  </thead>
                  <tbody>
                  <!-- ITEMS -->
                  <tr v-for="item in invoice.items" :key="item.position">
                    <td>{{ item.position }}</td>
                    <td>{{ item.description }}</td>
                    <td v-if="item.period">{{ item.period }}</td>
                    <td v-else>-</td>
                    <td>{{ item.netto }} €</td>
                    <td>{{ item.amount }}</td>
                    <td>
                      {{ item.tax_amount }} €
                    </td>

                    <td class="text-right">{{ item.brutto }} €</td>
                  </tr>
                  <!-- ITEMS END -->
                  <tr>
                    <td colspan="6" class="text-right">{{ $t('invoices.details.items.total') }} (Netto)</td>
                    <td class="text-right">{{ invoice.total.netto }} €</td>
                  </tr>
                  <tr>
                    <td colspan="6" class="border-0 text-right">{{ $t('invoices.details.items.tax') }} ({{ invoice.tax.amount }} % {{invoice.tax.abbreviation}})</td>
                    <td class="border-0 text-right">{{ invoice.total.tax }} €</td>
                  </tr>
                  <tr>
                    <td colspan="6" class="border-0 text-right">
                      <strong>{{ $t('invoices.details.items.total') }} (Brutto)</strong>
                    </td>
                    <td class="border-0 text-right">
                      <h4 class="m-0">{{ invoice.total.brutto }} €</h4>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="d-print-none">
                <div class="float-right">
                  <a href="javascript:window.print()" class="btn btn-light">
                    <i class="fa fa-print"></i>
                    {{ $t('invoices.details.print') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="col-lg-12 col-sm-12 row justify-content-center">
        <div class="col-lg-6 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row justify-content-center">
        <div class="col-lg-6 col-sm-12">
          <div class="card">
            <div class="card-body align-middle text-center">
              <VclList width="40vw" :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclList>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>

</template>
